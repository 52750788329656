import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Image } from "react-bootstrap"
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function OpenResearchLab () {
  return (
    <Layout pageInfo={{ pageName: "Publicaciones" }}>
    <SEO title="Publicaciones" />
    <br />
    <br />
    <h1>Publicaciones</h1>
    <br></br>
  </Layout>
  )
}